import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MasterService } from '../service/master.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {
  categoryId: string;
  private subscriptions: Subscription[] = [];
  newsList: any;
  newsCategory: string;
  categoryAllList: any;
  selectedDate: Date;
  nextWeek: Date;
  todayDate: string;
  disableNextweek: boolean = true;
  toDate: string; fromDate: string;
  constructor(
    private _masterService: MasterService,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private router: Router) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.closeNav();
    const empDetails = this._masterService.categoryBehaviorSubject.asObservable().subscribe(res => {
      if (res != null) {
        this.categoryAllList = res;
      } else {
        this.getCategory();
      }

    })
    this.subscriptions.push(empDetails);
    this.activatedRoute.params.subscribe(res => {
      this.categoryId = atob(res['CategoryId']);
    })
    let date = new Date();
    this.selectedDate = date;
    this.todayDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.getNewsByCategory(this.categoryId, this.todayDate);
  }
  getCategory() {
    const get = this._masterService.getCategory()
      .subscribe((res: any) => {
        if (res.length != 0 && res != null) {
          this.categoryAllList = res;
          this._masterService.categoryBehaviorSubject.next(res);
        }
      })
    this.subscriptions.push(get);
  }
  getNewsByCategory(categoryId, latest_date) {
    this.closeNav();
    const get = this._masterService.getLatesNewsByCategoryId(categoryId, latest_date, 0, 500)
      .subscribe((res: any) => {
        if (res.Data.length != 0 && res != null) {
          this.newsList = res.Data;
          this.toDate = res.WeekEnd;
          this.fromDate = res.WeekStart;
          if (this.newsList.length > 0) {
            this.newsCategory = this.newsList[0]['CategoryName'];
          } else {
            this.newsCategory = null;
            alert("News not available for this week!");
          }
        }
      })
    this.subscriptions.push(get);
  }

  setDate(event) {
    window.scrollTo(0, 0);
    this.getNewsByCategory(this.categoryId, event)
    if (this.todayDate > event) {
      this.disableNextweek = false;
      this.selectedDate = event;
    } else {
      this.disableNextweek = true;
    }
    this.closeNav();
  }
  nextweek() {
    window.scrollTo(0, 0);
    var today = new Date(this.selectedDate);
    var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);

    let date = this.datepipe.transform(nextweek, 'yyyy-MM-dd');
    this.getNewsByCategory(this.categoryId, date);
    this.selectedDate = nextweek;
    if (this.todayDate > date) {
      this.disableNextweek = false;
    } else {
      this.disableNextweek = true;
    }
    this.closeNav();
  }
  previousweek() {
    window.scrollTo(0, 0);
    var today = new Date(this.selectedDate);
    var preweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    let date = this.datepipe.transform(preweek, 'yyyy-MM-dd');
    this.getNewsByCategory(this.categoryId, date);
    this.selectedDate = preweek;

    if (this.todayDate > date) {
      this.disableNextweek = false;
    } else {
      this.disableNextweek = true;
    }
    this.closeNav();
  }
  newsDetails(newsId) {
    this.router.navigate(['/news_details', btoa(newsId)])
  }
  newsCategoryList(categoryId) {
    this.getNewsByCategory(categoryId, this.todayDate);
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
