import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MasterService } from '../service/master.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.css']
})
export class SideNavigationComponent implements OnInit {
  categoryAllList: any;
  private subscriptions: Subscription[] = [];
  userDetails: any;
  constructor(private _masterService: MasterService, private router: Router, @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const empDetails = this._masterService.categoryBehaviorSubject.asObservable().subscribe(res => {
      if (res != null) {
        this.categoryAllList = res;
      } else {
        this.getCategory();
      }
    })
    this.subscriptions.push(empDetails);
  }
  //Get all category
  getCategory() {
    const get = this._masterService.getCategory()
      .subscribe((res: any) => {
        if (res.length != 0 && res != null) {
          this.categoryAllList = res;
          this._masterService.categoryBehaviorSubject.next(res);
        }
      })
    this.subscriptions.push(get);
  }
  //Redirect article category list page
  newsCategoryList(categoryId) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/news_list', btoa(categoryId)]);
  }
}
