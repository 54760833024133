import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MasterService } from '../service/master.service';

@Component({
  selector: 'app-my-program',
  templateUrl: './my-program.component.html',
  styleUrls: ['./my-program.component.css']
})
export class MyProgramComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  categoryAllList: any;
  shareIcon: boolean = false;
  constructor(private _masterService: MasterService, private router: Router) { window.scrollTo(0, 0); }

  ngOnInit() {
    const empDetails = this._masterService.categoryBehaviorSubject.asObservable().subscribe(res => {
      if (res != null) {
        this.categoryAllList = res;
      } else {
        this.getCategory();
      }

    })
  }
  getCategory() {
    const get = this._masterService.getCategory()
      .subscribe((res: any) => {
        if (res.length != 0 && res != null) {
          this.categoryAllList = res;
          this._masterService.categoryBehaviorSubject.next(res);
        } else {
          // this.toastr.error("Data not found");
        }
      })
    this.subscriptions.push(get);
  }
  shreIcon() {
    this.shareIcon = true;
  }
  ShareToFaceBook(social) {
    var url = "https://www.shubhraviraj.in/";
    var text = 'Hey, I have scored 98% at Shubhra Viraj IAS Academy'
    if (social === 'facebook') {
      var shareUrl = `http://www.facebook.com/sharer/sharer.php?u=${url}`;
    } else if (social === 'linkedin') {
      var shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    } else if (social === 'twitter') {
      var shareUrl = `https://twitter.com/intent/tweet?url='${url}'&text=${text}'&hashtags=#india`;
    } else if (social === 'whatsapp') {
      var shareUrl = `https://api.whatsapp.com/send?text='${text}'%20'${url}`;
    }
    window.open(shareUrl, "NewWindow");
  }
  newsCategoryList(categoryId) {
    this.router.navigate(['/news_list', btoa(categoryId)])
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
