import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { ConfirmDialogService } from '../service/confirm-dialog.service';
import { MasterService } from '../service/master.service';

@Component({
  selector: 'app-bookmark-dialog',
  templateUrl: './bookmark-dialog.html',
  styles: [`
    .content-container{
        padding: 3px 0px 31px;
        font-size: 15px;
    }
  `]
})
export class BookmarkDialogComponent implements OnInit {

  private user: SocialUser;
  private loggedIn: boolean;
  userDetails: SocialUser;
  //constructor(private authService: AuthService, private _router: Router, private confirmDialog: ConfirmDialogService, private _masterService: MasterService) { }


  constructor(private authService: AuthService, private _router: Router, private confirmDialog: ConfirmDialogService, private _masterService: MasterService, public dialogRef: MatDialogRef<BookmarkDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  signInWithGoogle1(): void {    
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
      this.userDetails = res;
      
      localStorage.setItem("userDetails", JSON.stringify(res));
      this._masterService.googleLogin(JSON.parse(localStorage.getItem("userDetails"))).subscribe((res: any) => {
        let UserId = res[0]['UserID']    
        //alert(UserId);    
        localStorage.setItem('currentAffairUser', UserId);
        let Username = res[0]['FirstName']+' '+res[0]['LastName']
        localStorage.setItem('CAUsername', Username);
        let Email = res[0]['EmailID']
        localStorage.setItem('CAEmail', Email);
        let Mobile = res[0]['Mobile']
        localStorage.setItem('CAMobile', Mobile);
        localStorage.setItem("isLogin","Y");
        window.location.reload();
      })
      let currentUrl = this._router.url;
      this._router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.dialogRef.close(false);
      this._router.onSameUrlNavigation = 'reload';
      //isReadMore = true;
      //this._router.navigate(['/']);
      //alert('#'+currentUrl);
      //this._router.
      //this.ngOnInit();
      //localStorage.setItem("isLogin","Y");
      //window.location.reload();
      this._router.navigate(['#'+currentUrl]);      
      //window.location.reload();
    })
  }
}
