
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { MasterService } from '../service/master.service';
import { ConfirmDialogService } from '../service/confirm-dialog.service';
@Component({
  selector: 'app-bookmark-list',
  templateUrl: './bookmark-list.component.html',
  styleUrls: ['./bookmark-list.component.css']
})
export class BookmarkListComponent implements OnInit {
  categoryId: string;
  private subscriptions: Subscription[] = [];
  categoryAllList: any;
  bookmarkList: any;
  constructor(
    private _masterService: MasterService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private confirmDialog: ConfirmDialogService) { window.scrollTo(0, 0); }

  ngOnInit() {
    const empDetails = this._masterService.categoryBehaviorSubject.asObservable().subscribe(res => {
      if (res != null) {
        this.categoryAllList = res;
      } else {
        this.getCategory();
      }
    })
    this.subscriptions.push(empDetails);
    this.getBookmark();
  }
  getCategory() {
    const get = this._masterService.getCategory()
      .subscribe((res: any) => {
        if (res.length != 0 && res != null) {
          this.categoryAllList = res;
          this._masterService.categoryBehaviorSubject.next(res);
        } else {

        }
      })
    this.subscriptions.push(get);
  }
  getBookmark() {
    this.spinnerService.show();
    const get = this._masterService.getBookmarkUserId(localStorage.getItem('currentAffairUser'), 0, 500)
      .subscribe((res: any) => {
        this.spinnerService.hide();
        if (res.length != 0 && res != null) {
          this.bookmarkList = res.Data;
        } else {
          // alert("Bookmark not available");

        }
      })
    this.subscriptions.push(get);
  }
  unBookmark(newsBookMarkId) {
    const get = this._masterService.deleteBookmark(newsBookMarkId, localStorage.getItem('currentAffairUser'))
      .subscribe((res: any) => {
        if (res == 'Deleted') {
          this.confirmDialog.openBookmarkDialog("This news has been successfully removed.").afterClosed().subscribe(res => {
            if (res) {

            }
          })
          this.getBookmark();
        }
      })
    this.subscriptions.push(get);

  }
  newsDetails(newsId) {
    this.router.navigate(['/news_details', btoa(newsId)])
  }
  newsCategoryList(categoryId) {
    this.router.navigate(['/news_list', btoa(categoryId)])
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
