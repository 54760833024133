import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookmarkListComponent } from './bookmark-list/bookmark-list.component';
import { HomeComponent } from './home/home.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { NewsListComponent } from './news-list/news-list.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NewsofthedayComponent } from './newsoftheday/newsoftheday.component';
import { NewsListTagComponent } from './news-list-tag/news-list-tag.component';
import { MyProgramComponent } from './my-program/my-program.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'news_list/:CategoryId',
    component: NewsListComponent
  },
  {
    path: 'news_list_tag/:TagId',
    component: NewsListTagComponent
  },
  {
    path: 'news_details/:NewsId',
    component: NewsDetailsComponent
  },
  {
    path: 'news_of_day/:NewsOfTheDayId',
    component: NewsofthedayComponent
  },
  {
    path: 'bookmark-list',
    component: BookmarkListComponent
  },
  {
    path: 'my_program',
    component: MyProgramComponent
  },
  {
    path: 'google_login',
    component: GoogleLoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule { }
