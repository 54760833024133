import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { BookmarkListComponent } from './bookmark-list/bookmark-list.component';
import { DatePipe } from '@angular/common';
import { TruncatePipe } from './service/truncate-pipe.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NewsofthedayComponent } from './newsoftheday/newsoftheday.component';
import { NewsListTagComponent } from './news-list-tag/news-list-tag.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { MyProgramComponent } from './my-program/my-program.component';
import { ConfirmDialogService } from './service/confirm-dialog.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { BookmarkDialogComponent } from './bookmark-dialog/bookmark-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { GoogleLoginComponent } from './google-login/google-login.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { MasterService } from './service/master.service';
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    // add you google client id
    provider: new GoogleLoginProvider("498180087080-ant2km4i53rbacnh687m4f4n156iktsf.apps.googleusercontent.com")
  }
]);
export function provideConfig() {
  return config;
}
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    NewsListComponent,
    NewsDetailsComponent,
    BookmarkListComponent,
    TruncatePipe,
    NewsofthedayComponent,
    NewsListTagComponent,
    MyProgramComponent,
    ConfirmDialogComponent,
    BookmarkDialogComponent,
    GoogleLoginComponent,
    UserMenuComponent,
    SideNavigationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatDialogModule,
    SocialLoginModule
  ],
  providers: [DatePipe, NgxSpinnerService, ConfirmDialogService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmDialogComponent, BookmarkDialogComponent]
})
export class AppModule { }
