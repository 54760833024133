import { Component, OnInit } from '@angular/core';
import { MasterService } from '../service/master.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  categoryList: any[];
  getNewOfDay: any;
  categoryAllList: any;
  selectedDate: Date;
  nextWeek: Date;
  isDataAvailable: boolean = false;
  todayDate: string;
  disableNextweek: boolean = true;
  isReadMore = true;
  toDate: string; fromDate: string;
  userDetails: [];
  shareIcon: boolean = false;
  ipaddress:string = '';
  constructor(private _masterService: MasterService,
    private datepipe: DatePipe,
    private router: Router,
    private viewportScroller: ViewportScroller) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.getNewsOfTheDay();
    let date = new Date();
    this.selectedDate = date;
    this.todayDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.getLatestNewsOfWeek(this.todayDate);
    this.getCategory();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));      
    this._masterService.getIpAddress().subscribe(res => {
    this.InsertLog(localStorage.getItem("CAUsername"),localStorage.getItem("CAEmail"),localStorage.getItem("CAMobile"));
  });
       
    //this.InsertLog(localStorage.getItem("CAUsername"),localStorage.getItem("CAEmail"),localStorage.getItem("CAMobile"));
    //console.log("VisitorName = "+VisitorName);
    
  }

  InsertLog(VisitorName, EmailID, Mobile) {
    const get = this._masterService.InsertWebsiteVisitorLog(VisitorName, EmailID, Mobile)
      .subscribe((res: any) => {
        console.log(res);
      })
    this.subscriptions.push(get);
  }

  getLatestNewsOfWeek(todayDate) {
    const get = this._masterService.getLatestNewsOfweek(todayDate)
      .subscribe((res: any) => {
        if (res.length != 0 && res != null) {
          this.categoryList = res.Data;
          this.toDate = res.WeekEnd;
          this.fromDate = res.WeekStart;
          if (this.categoryList != null) {
            this.isDataAvailable = true;
          }
        }
      })
    this.subscriptions.push(get);
  }
  getCategory() {
    const get = this._masterService.getCategory()
      .subscribe((res: any) => {
        if (res.length != 0 && res != null) {
          this.categoryAllList = res;
          this._masterService.categoryBehaviorSubject.next(res);
        }
      })
    this.subscriptions.push(get);
  }
  getNewsOfTheDay() {
    const get = this._masterService.getNewsOfTheDay()
      .subscribe((res: any) => {
        if (res.length != 0 && res != null) {
          this.getNewOfDay = res;
        }
      })
    this.subscriptions.push(get);
  }
  setDate(event) {
    window.scrollTo(0, 0);
    this.getLatestNewsOfWeek(event);
    if (this.todayDate > event) {
      this.disableNextweek = false;
      this.selectedDate = event;
    } else {
      this.disableNextweek = true;
    }
  }
  nextweek() {
    window.scrollTo(0, 0);
    var today = new Date(this.selectedDate);
    var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);

    let date = this.datepipe.transform(nextweek, 'yyyy-MM-dd');
    this.getLatestNewsOfWeek(date);
    this.selectedDate = nextweek;

    if (this.todayDate > date) {
      this.disableNextweek = false;
    } else {
      this.disableNextweek = true;
    }
  }
  previousweek() {
    window.scrollTo(0, 0);
    var today = new Date(this.selectedDate);
    var preweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    let date = this.datepipe.transform(preweek, 'yyyy-MM-dd');
    this.getLatestNewsOfWeek(date);
    this.selectedDate = preweek;

    if (this.todayDate > date) {
      this.disableNextweek = false;
    } else {
      this.disableNextweek = true;
    }
  }
  shreIcon() {
    this.shareIcon = true;
  }
  ShareToFaceBook(social) {
    var url = "https://www.shubhraviraj.in/";
    var text = 'Hey, I have scored 98% at Shubhra Viraj IAS Academy'
    if (social === 'facebook') {
      var shareUrl = `http://www.facebook.com/sharer/sharer.php?u=${url}`;
    } else if (social === 'linkedin') {
      var shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    } else if (social === 'twitter') {
      var shareUrl = `https://twitter.com/intent/tweet?url='${url}'&text=${text}'&hashtags=#india`;
    } else if (social === 'whatsapp') {
      var shareUrl = `https://api.whatsapp.com/send?text='${text}'%20'${url}`;
    }
    window.open(shareUrl, "NewWindow");
  }
  onScrollMyProgram(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    this.viewportScroller.scrollToPosition([0, 1300]);
  }
  newsDetails(newsId) {
    this.router.navigate(['/news_details', btoa(newsId)])
  }
  newsOfDayDetails(newsOfTheDayId) {
    this.router.navigate(['/news_of_day', btoa(newsOfTheDayId)])
  }
  newsCategoryList(categoryId) {
    this.router.navigate(['/news_list', btoa(categoryId)])
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
