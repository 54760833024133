import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { BookmarkDialogComponent } from '../bookmark-dialog/bookmark-dialog.component';
@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private matDialog: MatDialog) { }
  openConfirmationDialog(msg) {
    return this.matDialog.open(ConfirmDialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg
      }
    });
  }
  openBookmarkDialog(msg) {
    return this.matDialog.open(BookmarkDialogComponent, {
      width: '390px',
      panelClass: 'bookmark-dialog-container',
      disableClose: true,
      data: {
        message: msg
      }
    });
  }
}
