import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { MasterService } from './service/master.service';
import jwt_decode from "jwt-decode";
declare var window: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private _router: Router, private _masterService: MasterService, @Inject(DOCUMENT) private _document: Document) { }
  title = 'shubhararanjan';
  ngOnInit() {
    let token = localStorage.getItem('currentaffairs');
    if (token == null) {
      this.getToken();
    }
    if (JSON.parse(localStorage.getItem("userDetails")) != null) {
      return;
    } else {
      window.google.accounts.id.initialize({
        // add you google client id
        client_id: '**************.apps.googleusercontent.com',
        callback: this.handleGgOneTap.bind(this)
      });
      window.google.accounts.id.prompt(notification => {

      });
    }

  }
  handleGgOneTap(resp) {
    const decodeToken = jwt_decode(resp.credential);
    localStorage.setItem("userDetails", JSON.stringify(decodeToken));

    this._masterService.googleOneTapLogin(JSON.parse(localStorage.getItem("userDetails"))).subscribe(res => {
      localStorage.setItem('currentAffairUser', res[0]['UserID']);
      let currentUrl = this._router.url;
      this._router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this._router.onSameUrlNavigation = 'reload';
      this._router.navigate(['/']);
    })

    // location.reload();
  }

  getToken() {
    this._masterService.getToken().subscribe(res => {
      localStorage.setItem('currentaffairs', res[0]['AccessToken']);
      this._document.defaultView.location.reload();
    })
  }
}
