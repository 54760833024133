import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { stringify } from '@angular/compiler/src/util';

const API_USERS_URL = `${environment.baseUrl}${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  headerOption = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('currentaffairs') });
  requestOption = { headers: this.headerOption };

  ImgheaderOption = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('currentaffairs') });
  ImgrequestOption = { headers: this.ImgheaderOption };
  ipAddress: string;
  constructor(private http: HttpClient, private router: Router) {
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        this.ipAddress = data.ip
      })
  }

  getIpAddress() {
    return this.http
          .get('https://jsonip.com')
          .pipe(
            catchError(this.handlerError)
          );
  } 
  private handlerError(errorResponse: HttpErrorResponse) {
    var errorMessage = '';
    if (errorResponse.error instanceof ErrorEvent) {
      if (errorResponse.status == 401) {
        localStorage.removeItem('currentaffairs');
        localStorage.removeItem('currentUser');
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        });
      }
      console.log("Client Side Error:", errorResponse.error.message);
      errorMessage = `Client Side Error: ${errorResponse}`;
    } else {
      if (errorResponse.status === 401) {
        localStorage.removeItem('currentaffairs');
        localStorage.removeItem('currentUser');
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        });
      }
      console.log("Server Side Error:", errorResponse);
      errorMessage = `Server Side Error: ${errorResponse}`;
    }
    return throwError(errorMessage);
  }

  categoryBehaviorSubject = new BehaviorSubject(null);
  getToken() {
    return this.http.post(`${API_USERS_URL}/DummyToken`, this.requestOption).pipe(catchError(this.handlerError))
  }
  googleOneTapLogin(body) {
    var payload = { 'GUID': body.id, 'FirstName': body.given_name, 'LastName': body.family_name, 'EmailID': body.email, 'IPAddress': this.ipAddress }
    return this.http.post(`${API_USERS_URL}/LoginStudentGoogle`, payload, this.requestOption).pipe(catchError(this.handlerError))
  }
  googleLogin(body) {
    var payload = { 'GUID': body.id, 'FirstName': body.firstName, 'LastName': body.lastName, 'EmailID': body.email, 'IPAddress': this.ipAddress }
    return this.http.post(`${API_USERS_URL}/LoginStudentGoogle`, payload, this.requestOption).pipe(catchError(this.handlerError))
  }
  getCategory(): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetAllNewsCategory`, this.requestOption).pipe(catchError(this.handlerError))
  }

  getNewsById(newsId): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetNewsById?NewsId=${newsId}`, this.requestOption).pipe(catchError(this.handlerError))
  }
  getLatesNewsByCategoryId(categoryId, date, pageNumber, pageSize): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetAllLatestsNewsByCategoryByWeekly?NewsCategoryId=${categoryId}&Date=${date}&pageNumber=${pageNumber}&pageSize=${pageSize}`, this.requestOption).pipe(catchError(this.handlerError))
  }
  getNewsByCategoryId(categoryId, date): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetLatestsNewsByCategoryId?NewsCategoryId=${categoryId}&Date=${date}`, this.requestOption).pipe(catchError(this.handlerError))
  }
  getLatesNewsByTagId(tagId, date, pageNumber, pageSize): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetAllNewsByTagByWeekly?TagId=${tagId}&Date=${date}&pageNumber=${pageNumber}&pageSize=${pageSize}`, this.requestOption).pipe(catchError(this.handlerError))
  }
  getLatesNewsByTagIdWeek(tagId): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetLatestNewsByTagByWeekly?TagId=${tagId}`, this.requestOption).pipe(catchError(this.handlerError))
  }

  getNewsOfTheDay(): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetLatestsNewsOfTheDay`, this.requestOption).pipe(catchError(this.handlerError))
  }
  getNewsOfTheDayById(newsOfTheDayId): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetNewsOfTheDayById?NewsOfTheDayId=${newsOfTheDayId}`, this.requestOption).pipe(catchError(this.handlerError))
  }
  getNewsOfTheDayByTag(tagId): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetNewsOfTheDayByTag?TagId=${tagId}`, this.requestOption).pipe(catchError(this.handlerError))
  }

  getLatestNewsOfweek(date): Observable<any> {
    return this.http.get(`${API_USERS_URL}/GetLatestsNewsByCategory?Date=${date}`, this.requestOption).pipe(catchError(this.handlerError))
  }
  saveBookmark(boobkmark) {
    return this.http.post(`${API_USERS_URL}/SaveUpdateNewsBookMark`, boobkmark, this.requestOption).pipe(catchError(this.handlerError))
  }
  getBookmarkUserId(userId, pageNumber, pageSize) {
    return this.http.get(`${API_USERS_URL}/GetAllNewsBookMarkUserId?UserId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, this.requestOption).pipe(catchError(this.handlerError))
  }
  deleteBookmark(newsBookMarkId, modifiedBy) {
    return this.http.delete(`${API_USERS_URL}/DeleteNewsBookMark?NewsBookMarkId=${newsBookMarkId}&ModifiedBy=${modifiedBy}`, this.requestOption).pipe(catchError(this.handlerError))
  }
  

  InsertWebsiteVisitorLog(VisitorName, EmailID, Mobile) {     
   var payload = { 'VisitorName': VisitorName, 'EmailID':EmailID, 'Mobile': Mobile, 'IPAddress': this.ipAddress }
    return this.http.post(`${API_USERS_URL}/InsertWebsiteVisitorLog`, payload, this.requestOption).pipe(catchError(this.handlerError))
  }

}

