import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { ConfirmDialogService } from '../service/confirm-dialog.service';
import { MasterService } from '../service/master.service';
@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.css']
})
export class GoogleLoginComponent implements OnInit {
  private user: SocialUser;
  private loggedIn: boolean;
  userDetails: SocialUser;
  constructor(private authService: AuthService, private _router: Router, private confirmDialog: ConfirmDialogService, private _masterService: MasterService) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
      this.userDetails = res;
      localStorage.setItem("userDetails", JSON.stringify(res));
      this._masterService.googleLogin(JSON.parse(localStorage.getItem("userDetails"))).subscribe((res: any) => {
        let UserId = res[0]['UserID']
        localStorage.setItem('currentAffairUser', UserId);
        let Username = res[0]['FirstName']+' '+res[0]['LastName']
        localStorage.setItem('CAUsername', Username);
        let Email = res[0]['EmailID']
        localStorage.setItem('CAEmail', Email);
        let Mobile = res[0]['Mobile']
        localStorage.setItem('CAMobile', Mobile);
      })
      let currentUrl = this._router.url;
      this._router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this._router.onSameUrlNavigation = 'reload';
      this._router.navigate(['/']);
    })
  }
  signOut(): void {
    this.confirmDialog.openConfirmationDialog("Are you sure you want to logout?").afterClosed().subscribe(res => {
      if (res) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem('currentAffairUser');
        this.authService.signOut();
        let currentUrl = this._router.url;
        this._router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
        this._router.onSameUrlNavigation = 'reload';
        this._router.navigate(['/']);
      }
    });
  }

}
