import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MasterService } from '../service/master.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { htmlToText } from 'html-to-text';
import { ConfirmDialogService } from '../service/confirm-dialog.service';
@Component({
  selector: 'app-newsoftheday',
  templateUrl: './newsoftheday.component.html',
  styleUrls: ['./newsoftheday.component.css']
})
export class NewsofthedayComponent implements OnInit {

  bookmarkForm: FormGroup;
  newsOfTheDayId: string;
  private subscriptions: Subscription[] = [];
  newsDetails: any;
  categoryAllList: any;
  bookmarkTrue: boolean;
  bookmarkList: any;
  isReadMore = true;
  isDataAvailable: boolean = false; isImgAvailable: boolean = false;
  constructor(
    private _masterService: MasterService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private spinnerService: NgxSpinnerService,
    private confirmDialog: ConfirmDialogService) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    if (localStorage.getItem('isLogin') == "Y")
    {
      this.isReadMore = false;
    }
    localStorage.setItem("isLogin","N");
    const empDetails = this._masterService.categoryBehaviorSubject.asObservable().subscribe(res => {
      if (res != null) {
        this.categoryAllList = res;
      } else {
        this.getCategory();
      }

    })
    this.subscriptions.push(empDetails);

    this.activatedRoute.params.subscribe(res => {
      this.newsOfTheDayId = atob(res['NewsOfTheDayId']);
    })
    this.getNewsDetails(this.newsOfTheDayId);
  }

  getCategory() {
    const get = this._masterService.getCategory()
      .subscribe((res: any) => {
        if (res.length != 0 && res != null) {
          this.categoryAllList = res;
          this._masterService.categoryBehaviorSubject.next(res);
        } else {
          // this.toastr.error("Data not found");
        }
      })
    this.subscriptions.push(get);
  }
  getNewsDetails(newsOfTheDayId) {
    this.spinnerService.show();
    const get = this._masterService.getNewsOfTheDayById(newsOfTheDayId)
      .subscribe((res: any) => {
        this.spinnerService.hide();
        if (res.length != 0 && res != null) {
          this.newsDetails = res[0];
          if (this.newsDetails.ImgUrl != null) {
            this.isImgAvailable = true;
          }

          if (this.newsDetails != null) {
            if (this.newsDetails.Tags.length > 0) {
              this.isDataAvailable = true;
            }
          }
        } else {
          // this.toastr.error("Data not found");
        }
      })
    this.subscriptions.push(get);
  }
  showText() {
    if (localStorage.getItem('currentAffairUser') == null) {
      this.confirmDialog.openBookmarkDialog("Please login first to read complete news.").afterClosed().subscribe(res => {

      })
    } else {
      this.isReadMore = !this.isReadMore;
    }
  }
  newsCategoryList(categoryId) {
    this.router.navigate(['/news_list', btoa(categoryId)])
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
  async generatePDF(action = 'open') {

    let tagList = [];
    const html = `${this.newsDetails.Description}`;
    const description = htmlToText(html, {
      wordwrap: false
    });
    if (this.newsDetails.Tags.length > 0) {
      for (let i = 0; i < this.newsDetails.Tags.length; i++) {
        tagList.push(this.newsDetails.Tags[i]['Tag']);

      }
    }

    let docDefinition = {
      content: [
        {
          columns: [
            [
              {
                image: await this.getBase64ImageFromURL(
                  `${this.newsDetails.ImgUrl}?auto=compress&cs=tinysrgb&dpr=1&w=500`
                ),
                fit: [510, 510],
                margin: [0, 0, 0, 20]
              },
              {
                text: `${this.newsDetails.Title}`,
                fontSize: 18,
                color: '#3c72b7',
                margin: [5, 0, 0, 5]
              },
              {
                text: `${this.newsDetails.Source}, ${this.datepipe.transform(this.newsDetails.NewsDate, 'yyyy-MM-dd')}`,
                fontSize: 10,
                color: '#333',
                margin: [0, 0, 0, 10]
              },

              {
                text: `${description}`,
                style: 'sectionDetails',
              },
              {
                text: `${tagList}`,
                style: 'sectionDetails',
              }
            ],

          ]
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 12,
          margin: [0, 15, 0, 10]
        },
        sectionDetails: {
          fontSize: 12,
          margin: [0, 0, 0, 15]
        }
      }
    };

    if (localStorage.getItem('currentAffairUser') == null) {
      this.confirmDialog.openBookmarkDialog("Please login first to download this news in PDF.").afterClosed().subscribe(res => {
      })
    } else {
      if (action === 'download') {
        pdfMake.createPdf(docDefinition).download();
      } else if (action === 'print') {
        pdfMake.createPdf(docDefinition).print();
      } else {
        pdfMake.createPdf(docDefinition).open();
      }
    }

  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
}
